body {
	margin: 0px 0px;
	padding: 0px;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #3c3c3c;
	line-height: 18px;
}

* {
	margin: 0px;
	padding: 0px;
	outline: none;
}

.padA {
	padding: 0px;
}

.padA-10 {
	padding: 10px;
}

.hor-line-dotted {
	border-bottom: 1px #dcdcdc dotted !important;
}

.border-line-dotted {
	border: 1px #dcdcdc dotted !important;
}

.border-1-input {
	border: 1px #ced4da dotted;
}

.text-underline {
	text-decoration: underline;
}

.text-bold {
	font-weight: 600;
}

.red-text-color {
	color: #E75A5A;
}

.black-text-color {
	color: #4E4E4E !important;
}

.grey-text-color {
	color: #A0A0A0 !important;
}

.green-text-color {
	color: #53D95C !important;
}

.yellow-text-color {
	color: #E7D95A !important;
}

.blue-text {
	color: #0094FF !important;
}

.light-grey-bg-color {
	background: #F7F7F7 !important;
}

.blue-bg-color {
	background: #0E90D1 !important;
}

.blue-broder-color {
	border: 1px solid #0E90D1 !important;
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.w-5 {
	width: 5%;
}

.w-10 {
	width: 10%;
}

.w-15 {
	width: 15%;
}

.w-20 {
	width: 20%;
}

.w-25 {
	width: 25%;
}

.w-35 {
	width: 35%;
}

.w-50 {
	width: 50%;
}

.w-70 {
	width: 70%;
}

.h-100 {
	min-height: 100px;
}

.h-200 {
	min-height: 200px;
}

.text-dark {
	color: #4E4E4E;
}

.text-grey {
	color: #999999;
}

.dis-flex {
	display: flex;
}

.border-0 {
	border: none;
}

.border-grey-1 {
	border: 1px #dcdcdc solid;
}

.bg-transparent {
	background: transparent;
}

.font-size-12 {
	font-size: 12px;
}

.font-size-14 {
	font-size: 14px;
}

.font-size-16 {
	font-size: 16px;
}

.font-size-18 {
	font-size: 18px;
}

.font-size-20 {
	font-size: 20px;
}

.font-size-22 {
	font-size: 22px;
}

.font-size-24 {
	font-size: 24px;
}

.font-size-26 {
	font-size: 26px;
}

.font-size-28 {
	font-size: 28px;
}

.font-weight-7 {
	font-weight: 500;
}

.font-weight-4 {
	font-weight: 400;
}

.font-weight-3 {
	font-weight: 400;
}

.display-block {
	display: block;
}

.display-inline-block {
	display: inline-block;
}

.display-none {
	display: none;
}

.pad-rl-5 {
	padding-left: 5px;
	padding-right: 5px;
}

.pad-r-5 {
	padding-right: 5px;
}

.orange-bg {
	background: #F58714;
}

.blue-light-bg {
	background: #00C7BF;
}

.blue-dark-bg {
	background: #512DA7;
}

.blue-bg {
	background: #0067DB;
}

.purple-bg {
	background: #9B01BF;
}

.red-bg {
	background: #EA2835;
}

.container-fluid container-xl {
	width: 90% !important;
	margin: 0px auto;
}

/*********************************/
.scrolled-top-header-home {
	background: rgb(205,229,247);
	background: linear-gradient(288deg, rgb(224 239 250) 0%, rgb(248 251 255) 55%, rgba(255,255,255,1) 98%);
	z-index: 1000;
	width: 100%;
}

.scrolled-top-header {
	background: rgb(205,229,247);
	background: linear-gradient(288deg, rgb(224 239 250) 0%, rgb(251 253 255) 55%, rgba(255,255,255,1) 98%);
	/* background: linear-gradient(288deg, rgb(244 249 253) 0%, rgb(255 255 255) 55%, rgba(255,255,255,1) 98%); */
	z-index: 1000;
	width: 100%;
}

.scrolled {
	background: rgb(205,229,247);
	background: linear-gradient(288deg, rgba(205,229,247,1) 0%, rgba(208,236,249,1) 55%, rgba(255,255,255,1) 98%);
	z-index: 1000;
	position: fixed;
	top: 0px;
	width: 100%;
}

	.scrolled ul li a {
		color: #333 !important
	}

		.scrolled ul li a:hover {
			border-bottom: 2px #5945EE solid !important;
		}

	.scrolled .navbar-brand img {
		width: 80%;
	}

	.scrolled .container {
		width: 90% !important;
		max-width: 90%;
	}

	.scrolled ul li {
		font-size: 14px !important;
		padding: 3px 7px;
		margin: 0px;
	}

		.scrolled ul li a {
			text-transform: uppercase;
			font-weight: 600;
			height: 30px;
			line-height: 12px;
		}

.header-custom-main {
	margin: 0px 0px;
	padding: 0px 0px;
	background: rgb(205,229,247);
	background: linear-gradient(338deg, rgba(205,229,247,1) 0%, rgba(205,229,247,1) 31%, rgba(255,255,255,1) 83%);
}

.custom-top-header {
	display: flex;
	justify-content: space-between;
	padding: 20px 10px;
	align-items: center;
}

.sign-up-btn {
	font-size: 16px;
	font-weight: 500;
	color: #001A5C;
	border: 1px #001A5C solid;
	height: 44px;
	min-width: 95px;
	line-height: 37px;
	text-align: center;
	padding: 0px 6px;
	border-radius: 30px;
	background: transparent;
	text-decoration: none;
	line-height: 43px;
}

.leaderboard-btn {
	font-size: 16px;
	font-weight: 500;
	color: #001A5C;
	border: 1px #001A5C solid;
	height: 44px;
	min-width: 150px;
	line-height: 37px;
	text-align: center;
	border-radius: 30px;
	background: transparent;
	text-decoration: none;
	line-height: 43px;
	padding-right: 8px;
}

	.leaderboard-btn img {
		width: 25%;
	}

.sign-up-btn svg {
	width: 80%;
}

.sign-up-btn:hover {
	color: #fff;
	background: #001A5C;
}

.login-btn {
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	height: 44px;
	min-width: 95px;
	text-align: center;
	padding: 0px 18px;
	line-height: 37px;
	border-radius: 30px;
	background: #0094FF;
	border: 1px #0094FF solid;
	text-decoration: none;
	line-height: 43px;
}

	.login-btn:hover {
		color: #0094FF;
		background: #fff;
	}

.learn-btn {
	min-width: 205px !important;
	font-size: 24px !important;
	font-weight: 400 !important;
	min-height: 50px !important;
}

.learn-btn-new {
	min-width: 225px !important;
	font-size: 24px !important;
	font-weight: 500;
	color: #fff;
	height: 62px;
	text-align: center;
	padding: 0px 20px;
	line-height: 37px;
	border-radius: 30px;
	background: #0094FF;
	border: 1px #0094FF solid;
	text-decoration: none;
}

	.learn-btn-new svg {
		font-size: 20px;
		font-weight: 400;
	}


.cus-widt-btn {
	min-width: 205px !important;
}

.banner-content-sec {
	display: flex;
	justify-content: space-between;
}

.banner-text-sec {
	margin: 80px 0px 0px 0px;
	position: relative;
	top: 10px;
	width: 50%;
}

	.banner-text-sec h1 {
		font-size: 60px;
		font-weight: 400;
		line-height: 70px;
		color: #001A5C;
	}

	.banner-text-sec p {
		font-size: 20px;
		font-weight: 400;
		line-height: 30px;
		color: #5F718D;
		margin: 30px 0px;
	}

.banner-img-sec {
	width: 55%;
	margin: 0px;
	padding: 0px;
}

	.banner-img-sec img {
		width: 112%;
	}


.main-middle-content-section {
	margin: 0px 0px;
}

.why-joinus-content-sec {
	display: flex;
	justify-content: space-between;
	margin: 0px 0px;
	padding: 0px 0px;
}

.joinus-content-sec {
	width: 50%;
	margin: 0px 0px;
	padding: 0px 80px 0px 0px;
	position: relative;
	top: 70px;
}

	.joinus-content-sec h4 {
		margin: 0px 0px 10px 0px;
		padding: 0px 0px;
		font-size: 20px;
		color: #0085FF;
		font-weight: 500;
		line-height: 30px;
	}

	.joinus-content-sec h2 {
		margin: 12px 0px 10px 0px;
		padding: 0px 0px;
		font-size: 48px;
		color: #001A5C;
		font-weight: 400;
		line-height: 58px;
	}

	.joinus-content-sec p {
		margin: 20px 0px 0px 0px;
		padding: 0px 0px;
		font-size: 20px;
		color: #5F718D;
		font-weight: 400;
		line-height: 30px;
	}

.why-joinus-content-sec figure {
	margin: 0px 0px;
	padding: 0px 40px;
	width: 50%;
	position: relative;
	right: 40px;
}

	.why-joinus-content-sec figure img {
		width: 100%;
		margin: 0px;
		padding: 0px 0px;
	}

.login-content-sec {
	position: relative;
	top: 70px;
	margin: 0px 0px;
	padding: 0px;
}

.right-btn-sec {
	display: flex;
	align-items: center;
	gap: 20px;
	margin: 0px;
	padding: 0px;
}

.already-acc-btn {
	font-size: 16px;
	color: #5F718D;
	margin: 0px;
	padding: 0px;
}

.recover-pass-content-sec {
	position: relative;
	top: 270px;
	margin: 0px 0px;
	padding: 0px;
}

.verify-content-sec {
	position: relative;
	top: 200px;
	margin: 0px 0px;
	padding: 0px 50px;
	text-align: left;
}

	.verify-content-sec h4 {
		font-size: 40px;
		font-weight: 400;
		margin: 0px 0px 30px 0px;
		padding: 0px 0px;
		color: #001A5C;
		line-height: 43px;
	}

	.verify-content-sec p {
		font-size: 16px;
		font-weight: 400;
		margin: 0px 0px 50px 0px;
		padding: 0px 0px;
		color: #5F718D;
		line-height: 27px;
	}

.change-pass-content-sec {
	position: relative;
	top: 200px;
	margin: 0px 0px;
	padding: 0px;
}


	.login-content-sec h4, .recover-pass-content-sec h4, .change-pass-content-sec h4 {
		font-size: 40px;
		font-weight: 400;
		margin: 0px 0px 15px 0px;
		padding: 0px 0px;
		color: #001A5C;
	}

	.recover-pass-content-sec p, .change-pass-content-sec p {
		font-size: 16px;
		font-weight: 400;
		margin: 0px 0px 30px 0px;
		padding: 0px 0px;
		color: #5F718D;
		line-height: 26px;
	}

.reword-content-sec {
	margin: 0px;
	padding: 0px 0px;
	position: relative;
	top: 70px;
}

	.reword-content-sec p {
		font-size: 16px;
		font-weight: 400;
		margin: 0px 0px 30px 0px;
		padding: 0px 0px;
		color: #5F718D;
	}

	.reword-content-sec h4 {
		font-size: 50px;
		font-weight: 400;
		margin: 0px 0px 20px 0px;
		padding: 0px 0px;
		color: #001A5C;
	}

	.reword-content-sec h5 {
		font-size: 20px;
		font-weight: 500;
		margin: 10px 0px 10px 0px;
		padding: 0px 0px;
		color: #001A5C;
	}

	.reword-content-sec p {
		font-size: 16px;
		font-weight: 400;
		margin: 0px 0px 30px 0px;
		padding: 0px 0px;
		line-height: 25px;
		color: #5F718D;
	}

.social-all-icon p {
	color: #001A5C;
	font-size: 20px;
	font-weight: 400;
	margin: 0px 0px 30px 0px;
	padding: 0px 0px;
	line-height: 25px;
}

.social-all-icon ul {
	list-style: none;
	margin: 0px 0px;
	padding: 0px 0px;
}

	.social-all-icon ul li {
		width: 55px;
		height: 55px;
		border-radius: 50%;
		background: #0094FF;
		color: #fff;
		text-align: center;
		display: inline-block;
		padding: 14px 0px 0px 0px;
		margin: 0px 20px 20px 0px;
	}

		.social-all-icon ul li a {
			color: #fff;
			font-size: 26px;
		}

.login-mess-container {
	width: 60%;
	min-height: 73px;
	border: 1px #37e2c3 solid;
	border-radius: 10px;
	background: #d1fbf3;
	font-size: 16px;
	font-weight: 400;
	color: #001A5C;
	padding: 24px;
	margin-bottom: 20px;
}

.forgot-password-sec {
	display: flex;
	align-items: center;
	color: #5F718D;
	gap: 10px;
	font-size: 14px;
	padding: 20px 0px;
	font-weight: 400;
}

	.forgot-password-sec a {
		color: #5F718D;
	}

.have-account-already {
	font-size: 16px;
	font-weight: 400;
	color: #5F718D;
	margin-top: 30px;
}

.keep-sign-text {
	padding: 10px 0px !important;
}

	.keep-sign-text label {
		display: flex;
		gap: 20px;
		font-size: 14px;
		padding: 0px 0px;
		font-weight: 400;
		margin-bottom: 20px;
	}

		.keep-sign-text label input[type=checkbox] {
			width: 24px;
			height: 24px;
			border-radius: 4px;
		}

		.keep-sign-text label span {
			position: relative;
			top: 4px;
		}

.login-input-content-sec {
	margin: 0px 0px;
	padding: 0px 0px;
	display: flex;
	justify-content: space-between;
}

.section-speretor {
	display: flex;
	gap: 20px;
	align-items: center;
	margin: 15px 0px;
}

	.section-speretor hr {
		border: 1px #E0E0E0 solid;
		width: 45%;
	}

	.section-speretor span {
		color: #CDCDCD;
		font-size: 16px;
		font-weight: 400;
	}

.input-section-main {
	margin: 20px 0px;
	padding: 0px 0px;
}

	.input-section-main input[type=email], .input-section-main input[type=password], .input-section-main input[type=text] {
		width: 100%;
		height: 71px;
		border: 1px #DBDEE9 solid;
		padding: 0px 30px;
		border-radius: 10px;
	}

.countryCode__control {
	width: 100%;
	height: 71px;
	border: 1px #DBDEE9 solid;
	padding: 0px 20px;
	border-radius: 10px;
}

.countryCode__value-container, .countryCode__indicators {
	height: 71px;
}

.css-1jqq78o-placeholder {
	position: relative;
	font-size: 1rem !important;
	font-weight: 400 !important;
}

.login-page-btn {
	font-size: 20px;
	font-weight: 500;
	color: #fff;
	min-height: 71px;
	width: 100%;
	text-align: center;
	line-height: 30px;
	border-radius: 30px;
	background: #0094FF;
	border: 1px #0094FF solid;
}

.login-back-btn {
	font-size: 20px;
	font-weight: 500;
	color: #001A5C;
	min-height: 71px;
	width: 40%;
	text-align: center;
	line-height: 65px;
	border-radius: 30px;
	background: transparent;
	border: 1px #001A5C solid;
}

.social-media-btn {
	margin: 0px 0px 30px 0px;
	display: flex;
	gap: 20px;
}

.google-scoial-btn {
	font-size: 16px;
	font-weight: 400;
	color: #5F718D;
	padding: 0px 20px;
	min-height: 61px;
	width: 50%;
	background: #fff;
	border-color: transparent;
	border-radius: 10px;
	text-align: left;
	display: flex;
	align-items: center;
	gap: 10px;
}

.linkedln-scoial-btn {
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	padding: 0px 20px;
	min-height: 61px;
	width: 50%;
	background: #0C64C5;
	border-color: transparent;
	border-radius: 10px;
	text-align: left;
	display: flex;
	align-items: center;
	gap: 10px;
}

.rt-img-login-sec {
	margin: 0px;
	padding: 0px 0px 0px 0px;
	display: flex;
}

	.rt-img-login-sec img {
		position: relative;
	}

.text-danger {
	margin-top: 10px;
	position: relative;
	top: 5px;
}

.custom-set {
	width: 122%;
	position: relative;
	right: 43px;
}

.reset-password-container {
	width: 55%;
	min-height: 342px;
	border: 1px #37e2c3 solid;
	border-radius: 10px;
	background: #d1fbf3;
	font-size: 20px;
	font-weight: 400;
	color: #001A5C;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	line-height: 30px;
	padding: 0px 40px;
	flex-direction: column;
	position: relative;
	left: 10%;
}

	.reset-password-container figure {
		width: 120px;
		height: 120px;
		border-radius: 50%;
		margin-bottom: 20px;
		background: #61FFE3;
		font-size: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

/******************/
.leaderboad-list-main-contianer {
	border-radius: 25px;
	background: #fff;
	border: 1px #E7E7E7 solid;
	padding: 20px 30px;
}

.leaderboard-header-sec {
	display: flex;
	justify-content: space-between;
	gap: 0px;
	margin: 0px 0px 20px 0px;
	align-items: center;
	padding: 0px 0px;
}

	.leaderboard-header-sec p {
		font-size: 24px;
		color: #001A5C;
		font-weight: 400;
		margin: 0px 0px;
		padding: 0px 0px;
	}

.lt-pannel-head {
	display: flex;
	justify-content: start;
	gap: 20px;
	margin: 0px 0px 0px 0px;
	align-items: center;
}

	.lt-pannel-head h3 {
		font-size: 24px;
		font-weight: 500;
		margin: 0px 0px;
		padding: 0px 0px;
		color: #001A5C;
	}

	.lt-pannel-head figure {
		margin: 0px 0px;
		padding: 0px 0px;
		width: 60px;
	}

		.lt-pannel-head figure img {
			width: 100%;
		}

.leader-list-main {
	margin: 20px 0px 0px 0px;
	padding: 0px;
}

	.leader-list-main ul {
		list-style: none;
		margin: 0px 0px;
		padding: 0px 0px;
	}

		.leader-list-main ul li {
			display: flex;
			gap: 20px;
			margin: 10px 0px 0px 0px;
			padding: 10px 0px 20px 0px;
			border-bottom: 1px #D9D9D9 solid;
			align-items: center;
			justify-content: space-between;
		}

.lt-pannel-sec {
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 20px;
	align-items: center;
	margin: 0px 0px;
	padding: 0px 0px;
}

	.lt-pannel-sec p {
		margin: 0px 0px;
		padding: 0px;
		font-size: 16px;
		font-weight: 400;
		color: #001A5C;
	}

	.lt-pannel-sec span {
		width: 45px;
		height: 45px;
		background: #B8E0FF;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
		color: #001A5C;
	}

.rt-pannel-sec {
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 20px;
	align-items: center;
	margin: 0px 0px;
	padding: 0px 0px;
}

	.rt-pannel-sec span {
		background: rgb(76, 209, 185,10%);
		border: 1px #4CD1B9 solid;
		border-radius: 30px;
		display: flex;
		padding: 12px 20px;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
		color: #4CD1B9;
	}

	.rt-pannel-sec figure {
		width: 50px;
		margin: 0px;
		font-size: 40px;
		display: flex;
	}

		.rt-pannel-sec figure img {
			width: 100%;
		}

.close-reword-sec-btn {
	background: #fff;
	padding: 0px 0px;
	text-align: center;
	color: #0094FF;
	font-size: 20px;
	font-weight: 500;
	min-height: 68px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	line-height: 30px;
	border-radius: 30px;
	border: 1px #0094FF solid;
	width: 100%;
	margin: 20px 0px;
}

.reword-top-list-main {
	margin: 20px 0px 0px 0px;
	padding: 0px;
}

	.reword-top-list-main ul {
		list-style: none;
		margin: 0px 0px;
		padding: 0px 0px;
	}

		.reword-top-list-main ul li {
			width: 48%;
			display: inline-block;
			background: #fff;
			border: 1px #E7E7E7 solid;
			border-radius: 30px;
			margin: 0px 10px 15px 0px;
			padding: 0px 20px;
		}

			.reword-top-list-main ul li .list-main-con {
				display: flex;
				gap: 10px;
				align-items: center;
				justify-content: space-between;
				margin: 0px 0px;
				padding: 0px;
				height: 68px;
			}

				.reword-top-list-main ul li .list-main-con figure {
					margin: 0px 0px;
					padding: 0px 0px;
					display: flex;
					gap: 10px;
					align-items: center;
				}
/* 
.reword-top-list-main ul li:last-child{
	width: 48%;
    display: inline-block;
    border-radius: 30px;
    margin: 0px 10px 15px 0px;
    padding: 0px 20px;
    font-size: 20px;
    font-weight: 500;
    color: #0094FF;
    text-align: center;
    line-height: 60px;
    background: #fff;
    border: 1px #0094FF solid;
    text-decoration: none;
    height: 65px;
    position: relative;
    bottom: 14px;
} */

.view-all-btn svg {
	font-size: 20px;
	font-weight: 400;
}

.reword-top-list-main ul li .list-main-con figure .reword-list-title {
	font-size: 16px;
	font-weight: 500;
	color: #001A5C;
}

.reword-top-list-main ul li p {
	font-size: 16px;
	color: #001A5C;
	font-weight: 400;
	margin: 0px 0px;
	padding: 0px 0px;
}

.reword-top-list-main ul li .price-sapce {
	background: rgb(76, 209, 185,10%);
	border: 1px #4CD1B9 solid;
	border-radius: 30px;
	display: flex;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: 500;
	color: #4CD1B9;
	min-width: 88px;
}

.middle-line-sm {
	color: #00227A !important;
}

.top-menu-icon {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background: #0094FF;
	position: absolute;
	top: 34px;
	left: 28px;
}

.input-copy-sec-main {
	margin: 20px 0px;
	padding: 0px 0px;
	position: relative;
}

	.input-copy-sec-main input {
		background: #fff;
		border: 1px #E7E7E7 solid;
		height: 68px;
		border-radius: 30px;
		padding: 0px 30px;
		font-size: 20px;
		color: #001A5C;
		font-weight: 400;
		width: 100%;
	}

	.input-copy-sec-main span {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: #0094FF;
		color: #fff;
		font-size: 20px;
		text-align: center;
		display: inline-block;
		padding: 14px 0px 0px 0px;
		margin: 0px 20px 0px 0px;
		position: absolute;
		right: -10px;
		top: 63px;
	}

.reword-card-sec-main {
	display: flex;
	margin: 30px 0px;
	padding: 0px 0px;
	gap: 20px;
}

.reword-card-sec {
	min-width: 22%;
	background: #fff;
	border: 1px #E7E7E7 solid;
	border-radius: 25px;
	padding: 20px 20px;
	margin: 20px 0px;
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	min-height: 170px;
	flex-direction: column;
	justify-content: center;
}

	.reword-card-sec p {
		font-size: 20px;
		font-weight: 500;
		color: #5F718D;
		margin: 0px 0px;
		padding: 14px 20px;
		margin: 0px 0px;
		line-height: 24px;
		margin-bottom: 10px;
	}

	.reword-card-sec h3 {
		font-size: 50px;
		font-weight: 500;
		color: #001A5C;
		margin: 0px 0px;
		padding: 0px 20px;
		margin: 0px 0px;
		line-height: 24px;
	}

	.reword-card-sec span {
		background: #E3F3FF;
		border-radius: 30px;
		font-size: 12px;
		font-weight: 500;
		color: #0094FF;
		padding: 10px 20px;
		position: absolute;
		top: -17px;
	}

.footer-main {
	margin: 0px 0px;
	padding: 0px 0px 0px 0px;
	background: #001A5C;
}

.footer-line {
	border-bottom: 1px #00227A solid;
	margin: 0px 0px;
}

.cus-pb-5 {
	padding-bottom: 70px !important;
}

.cus-pt-5 {
	padding-top: 70px !important;
}

.logout-rt-top-sec {
	display: flex;
	gap: 22px;
	align-items: center;
	margin: 0px 0px;
	padding: 0px 0px;
}

	.logout-rt-top-sec button {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: #B8E0FF;
		border: 1px #B8E0FF solid;
		margin: 0px 0px;
		position: relative;
		padding: 0px 0px;
	}

	.logout-rt-top-sec h5 {
		font-size: 18px;
		font-weight: 500;
		color: #001A5C;
		margin: 0px 0px;
		padding: 0px 0px;
	}

.footer-content-sec {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}

.footer-logo-sec {
	margin: 0px 0px;
	padding: 0px 40px 0px 0px;
	width: 50%;
	border-right: #00227A 1px solid;
}

.footer-nav-sec {
	margin: 0px 0px;
	padding: 0px 0px 0px 70px;
	width: 50%;
}

	.footer-nav-sec h3, .footer-logo-sec h3 {
		font-size: 24px;
		font-weight: 400;
		color: #0094FF;
		margin: 0px 0px;
		padding: 0px;
	}

	.footer-nav-sec p, .footer-nav-sec a, .footer-logo-sec, .footer-logo-sec a {
		font-size: 14px;
		font-weight: 400;
		color: #fff;
		line-height: 24px;
		margin: 0px 0px;
		text-decoration: none;
	}

	.footer-nav-sec ul {
		margin: 30px 0px 0px 0px;
		padding: 0px 0px;
		list-style: none;
	}

		.footer-nav-sec ul li {
			font-size: 14px;
			font-weight: 400;
			color: #fff;
			display: inline-block;
			line-height: 24px;
			margin: 0px 30px 0px 0px;
			padding: 0px;
		}

.deskstop-show {
	display: none;
}

.border-rt-nav {
	border-right: 1px #00227A solid;
	padding-right: 28px;
}
/*********** media query start here *********/

@media screen and (max-width: 768px) {
	.cus-pb-5-m {
		padding-bottom: 30px !important;
	}

	.banner-img-sec img {
		width: 100%;
	}

	.custom-set {
		width: 100%;
		position: relative;
		right: 0px;
	}

	.footer-logo-sec {
		border: none;
	}

	.footer-nav-sec {
		padding: 0px 0px 0px 0px;
	}

	.cus-pt-5 {
		padding-top: 30px !important;
	}

	.social-media-btn {
		margin: 0px 0px 0px 0px;
	}

	.mobile-line-cus-set {
		position: relative;
		top: 40px;
	}

	.mobile-postion-set {
		position: relative;
	}

	.custom-mobile-set-mail {
		position: absolute;
		bottom: 30%;
		width: 80% !important;
	}

	.cus-pb-5 {
		padding-bottom: 0px !important;
	}

	.custom-mobile-set-nav {
		position: absolute;
		top: 57%;
		width: 99% !important;
	}

	.deskstop-show {
		display: block;
	}

	.footer-line {
		margin: 30px 0px !important;
	}

	.footer-nav-sec ul li {
		margin: 0px 12px 10px 0px !important;
	}

	.mobile-none {
		display: none;
	}

	.footer-nav-sec .mt-5, .footer-logo-sec .mt-5 {
		margin-top: 0rem !important;
	}

	.learn-btn {
		font-size: 15px !important;
		min-width: 140px !important;
		max-width: 100%;
		width: 100%;
	}

	.navbar-brand .img-1 {
		width: 90% !important;
	}

	.navbar-brand .img-2 {
		width: 45% !important;
	}


	.rt-img-login-sec img {
		width: 100%;
		position: relative;
		left: 0px !important;
		margin-top: 30%;
	}

	.footer-logo-sec {
		width: 100%;
		flex-direction: column;
		display: flex;
	}

	.footer-content-sec {
		flex-direction: column;
		gap: 20px;
	}

	.footer-nav-sec {
		width: 100%;
	}

		.footer-nav-sec ul {
			margin-top: 20px !important;
		}

	.change-pass-content-sec {
		top: 40px;
	}

	.login-content-sec {
		top: 30px;
	}

	.login-mess-container {
		width: 100%;
		font-size: 14px;
		padding: 24px;
	}

	.login-content-sec h4 {
		font-size: 35px;
	}

	.login-content-sec p {
		font-size: 15px;
	}

	.recover-pass-content-sec {
		top: 40px;
	}

	.verify-content-sec {
		top: 40px;
		padding: 0px 10px !important;
	}

		.verify-content-sec h4 {
			font-size: 30px;
			line-height: 43px;
		}

		.verify-content-sec p {
			font-size: 16px;
			line-height: 27px;
		}

	.recover-pass-content-sec {
		top: 60px;
	}

	.reset-password-container {
		width: 100%;
		min-height: 442px;
		line-height: 40px;
		padding: 0px 40px;
		left: 0%;
	}

	.banner-content-sec {
		flex-direction: column;
	}

	.banner-img-sec {
		width: 100%;
	}

	.banner-text-sec h1 {
		font-size: 33px;
		font-weight: 400;
		line-height: 40px;
	}

	.banner-text-sec {
		margin: 10px 0px 0px 0px;
		top: 30px;
		width: 100%;
		padding: 0px 10px;
	}

	.banner-img-sec {
		margin-top: 55px;
	}

	.right-btn-sec {
		gap: 15px;
	}

	.reword-top-list-main ul li:last-child {
		width: 100%;
		/*margin-top: 20px;*/
	}

	.login-btn, .sign-up-btn {
		font-size: 14px;
	}

	.why-joinus-content-sec {
		flex-direction: column;
		margin: 10px !important;
		padding: 0px !important;
	}

	.mobile-cus-flex {
		flex-direction: column-reverse !important;
	}

	.joinus-content-sec {
		width: 100%;
		top: 20px;
		padding: 0px;
	}

	.mobile-cus-p {
		padding: 0px !important;
	}

	.joinus-content-sec h2 {
		margin: 14px 0px 20px 0px;
		font-size: 33px;
		line-height: 41px;
	}

	.why-joinus-content-sec figure {
		margin: 45px 0px 0px 0px;
		padding: 0px 0px;
		width: 100%;
		right: 0px;
	}

	.footer-nav-sec {
		padding: 0px 20px 0px 20px;
	}

	.footer-logo-sec {
		padding: 40px 20px 0px 20px;
	}

	.reword-content-sec h4 {
		font-size: 35px;
	}

	.reword-top-list-main ul li {
		width: 100%;
	}

	.reword-card-sec-main {
		margin: 0px 0px !important;
		flex-direction: column;
	}

	.reword-card-sec {
		width: 100% !important;
		margin-top: 0px !important;
		margin-bottom: 50px !important;
	}

	.remove-margin-cus {
		margin-bottom: 20px !important;
	}

	.leaderboad-list-main-contianer {
		padding: 20px 10px !important;
	}

	.rt-pannel-sec span {
		padding: 8px 12px;
		font-size: 13px;
	}

	.rt-pannel-sec, .lt-pannel-sec {
		gap: 5px !important;
	}

	.leaderboard-header-sec {
		align-items: flex-start;
		flex-direction: column;
		position: relative;
	}

		.leaderboard-header-sec p {
			font-size: 14px;
			left: 24%;
			bottom: 10px;
			position: relative;
		}

	.leader-list-main ul li {
		display: flex;
		gap: 20px;
		margin: 10px 0px 0px 0px;
	}

	.banner-text-sec p {
		font-size: 16px !important
	}

	.privacyBanner1 {
		padding: 25px 0;
		position: relative !important;
		top: auto !important;
	}

	#privacyBanner {
		background-color: #eaf5fd !important;
		background-position-x: 90% !important;
		background-position-y: bottom !important;
		background-size: 150% !important;
		height: 450px !important;
	}

	#teamBanner .teamBanner1 {
		position: relative;
		top: auto;
	}

	#teamBanner p {
		margin-top: 20px !important;
	}

	#teamBanner {
		background-position: -150px bottom !important;
		height: 500px !important;
		background-size: 150% !important;
		background-color: #cee9fc !important;
		padding-bottom: 3rem !important;
		padding-top: 3rem !important;
	}

	.otpList li {
		width: 25px !important;
	}

		.otpList li input {
			padding: 10px !important;
			width: 46px !important;
			min-height: 60px;
		}

	.Verify-otp-div input[type="text"] {
		width: 44px !important;
		margin: 0px 0px !important;
		text-align: center !important;
		font-size: 22px !important;
		font-weight: 350 !important;
	}

	.custom-padding {
		padding: 20px 150px !important;
	}
}

.float-left {
	float: left;
}

.hand {
	cursor: pointer
}

.Verify-otp-div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 40px 0px 10px;
}

	.Verify-otp-div input[type="text"] {
		float: left;
		font-size: 30px;
		font-weight: 350;
		height: 64px;
		margin: 0 0 20px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}

.otpList {
	display: flex;
	padding-left: 0px;
}

	.otpList li {
		width: 46px;
		margin-right: 32px;
		list-style-type: none;
	}

		.otpList li input {
			width: 56px;
			padding: 18px;
			text-align: center;
			font-size: 20px;
			font-weight: 600;
			color: #001a5c;
			border: 1px solid #eee;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		}

.twoFactor p {
	color: #5f718d;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 15px;
}

#teamBanner {
	background-image: url(../public/Image/termsOfUse.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	height: 655px;
}

	#teamBanner h4 {
		font-size: 40px;
		color: #001a5c;
	}

	#teamBanner p {
		font-size: 18px;
		line-height: 30px;
		margin-top: 200px;
		color: #001a5c;
	}

.teamBanner1 {
	top: 361px;
	position: absolute;
}

.termsBnanerSection {
	background: linear-gradient( -80.37deg, rgba(0, 148, 255, 0.2) 11.06%, rgba(217, 217, 217, 0) 102.87%, rgba(217, 217, 217, 0) 102.87%, rgba(255, 255, 255, 0.2) 102.87% );
}

#privacyBanner {
	background-image: url(../public/Image/privacypolicyHeader.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	height: 655px;
}

	#privacyBanner h4 {
		font-size: 40px;
		color: #001a5c;
	}

	#privacyBanner p {
		font-size: 18px;
		line-height: 30px;
		margin-top: 200px;
		color: #001a5c;
	}

.privacyBanner1 {
	top: 361px;
	position: absolute;
}

.termsConditionsheading {
	color: #000;
	font-size: 30px;
	margin-top: 110px;
}

.privacyPolicyHeading {
	color: #000;
	font-size: 30px;
	margin-top: 100px;
}

.addressALM {
	color: #000;
	font-size: 15px;
	line-height: 22px;
	margin: 0;
}

.paratermsCondition {
	color: #000;
	line-height: 25px;
	font-size: 16px;
}

.cookies, .hyperLink, .licence {
	color: #000;
	font-size: 20px;
	font-weight: 700;
}

a, a:hover {
	text-decoration: none;
}

.login-content-sec p {
	font-size: 16px;
	color: #5F718D;
	margin: 20px 0px 10px 0px;
}

.termsCondtion {
	font-size: 16px;
}

.termsCondtions {
	margin-bottom: 4px;
}

.eye-iconset {
	position: relative;
}

	.eye-iconset i {
		position: absolute;
		right: 29px;
		top: 23px;
		font-size: 25px;
		color: #5F718D;
	}

.passwordIcon span img {
	width: 25px;
}

.passwordIcon span {
	align-items: center;
	display: flex;
	height: 100%;
	padding: 0 20px 0 0;
	position: absolute;
	right: 0;
	top: 0;
}

.custom-padding {
	padding: 20px 210px;
}


.drop-dwon-popup {
	width: 246px;
	min-height: 86px;
	border-radius: 8px;
	padding: 20px 20px 10px 20px;
	border: 1px #E7E7E7 solid;
	background: #fff;
	position: absolute;
	top: 54px;
	right: 68px;
	z-index: 999;
}

	.drop-dwon-popup ul {
		list-style: none;
	}

.logout-rt-top-sec {
	position: relative;
}

.drop-dwon-popup ul li {
	font-size: 16px;
	color: #001A5C;
	margin: 10px 0px 0px 0px;
	padding: 0px 0px 10px 0px;
}

.drop-dwon-popup ul li i {
	margin-right: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}
.text-success {
	margin-top: 10px !important;
}
.clear {
	clear: both
}